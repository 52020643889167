import React, { useEffect } from 'react'
import { Grid, Message, Icon, Header, HeaderContent,  } from 'semantic-ui-react'
import DOMPurify from 'dompurify'  


const NextStepComponent = ({message, typeMessage, url}) => {
    const [showUrl, setShowUrl] = React.useState('');   
    useEffect(() => {
        setShowUrl(url);
        if(typeMessage==='REDIRECT'){
            window.location.replace(url);
        }
    }, [url]);
    return (
        <div>
            {typeMessage==='EMBEDDED' && (
                <iframe src={showUrl}
                    style={{display: "block", width:"100%", border:"none", height: "100vh"}}
                />
            )}
            <Grid columns='three' stackable>
                <Grid.Row/>
                    <Grid.Row>
                        <Grid.Column/>
                            <Grid.Column>
                            {typeMessage==='ERROR' && (
                                <>                          
                                  <div className='not-found'>   
                                    <Message floating>
                                        <Header as='h1' icon textAlign='center' style={{fontSize:'xx-large'}}>
                                            <Icon name='warning circle' style={{color: '#cc0101'}} />
                                                <HeaderContent style={{color:'#cc0101'}}>
                                                    <div>Payment processing error</div>
                                                    <p style={{fontSize: 'large', color: '#333333'}}>{message}</p>
                                                </HeaderContent>
                                        </Header>
                                    </Message>
                                  </div>
                                </>
                            )}
                            {typeMessage==='MESSAGE' && (
                                <>
                                    <div className='not-found'>
                                    <Message floating>   
                                        <Header as='h1' icon textAlign='center' style={{fontSize:'xx-large'}}>
                                        <Icon name='check circle outline' style={{color: '#008000'}} />
                                        <HeaderContent style={{color:'#008000'}}>
                                            <div>Payment has been processed</div>
                                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message)}} style={{fontSize: 'large', color: '#333333'}}></div>
                                        </HeaderContent>
                                        </Header>
                                    </Message>
                                    </div>
                                </>
                            )}
                            </Grid.Column>
                    <Grid.Column/>
                </Grid.Row>
            </Grid>
        </div>
        
    )
}
  

export default NextStepComponent