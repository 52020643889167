import React from "react";
import { Accordion, Icon, Header, Grid, Label, Flag } from "semantic-ui-react";
import InputDynamicValidationForm from "./InputDynamicValidationForm";
import getSymbolFromCurrency from "currency-symbol-map";

import { useEffect } from "react";

const AccordionProviderButton = ({
  providerList,
  mandatoryFieldMap,
  setMandatoryFieldMap,
  checkMandatoryFieldMap,
  tenant,
}) => {
  const [state, setState] = React.useState({ activeIndex: 0 });
  const { activeIndex } = state;
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;
    setState({ activeIndex: newIndex });
    handleMandatoryField("aggregatorId", providerList[newIndex].aggregatorId);
  };

  const handleMandatoryField = (key, value) => {
    //constrolar que el valor no sea nulo
    if (mandatoryFieldMap.has("aggregatorId") && key === "aggregatorId") {
      if (mandatoryFieldMap.get("aggregatorId") !== value) {
        mandatoryFieldMap.clear();
        handleMandatoryField("aggregatorId", providerList[activeIndex].aggregatorId);
      }
    }
    if (mandatoryFieldMap.has(key)) {
      mandatoryFieldMap.delete(key);
    }
    if (value != undefined && value != "") {
      mandatoryFieldMap.set(key, value);
    }
    setMandatoryFieldMap(mandatoryFieldMap);
    checkMandatoryFieldMap();
  };

  function getMoney(iso2) {
    const symbol = getSymbolFromCurrency(iso2);
    return symbol || iso2;
  }

  const formatNumber = (amount) => {
    if (amount != null && amount !== "") {
      return Number(amount).toFixed(2);
    }
    return Number("0").toFixed(2);
  };

  const formatLowerCase = (str) => {
    return str.toLowerCase();
  };

  useEffect(() => {
    if (providerList.length > 0) {
      handleMandatoryField("aggregatorId", providerList[activeIndex].aggregatorId);
    }
  }, [providerList]);

  return (
    <Accordion styled>
      {providerList.map((element, index) => (
        <div key={index}>
          <Accordion.Title
            key={element.aggregator}
            active={activeIndex === index}
            index={index}
            onClick={handleClick}
          >
            <Grid columns="equal">
              {element.image?.trim() ? (
                <Grid.Column
                  style={{
                    fontSize: "medium",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img
                    src={element.image}
                    alt={element.description}
                    style={{ maxHeight: "50px", maxWidth: "100px" }}
                  />
                  <span>{element.description}</span>
                </Grid.Column>
              ) : (
                <Grid.Column
                  style={{
                    fontSize: "medium",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon name="dropdown" />
                  {element.description}
                </Grid.Column>
              )}
              <Grid.Column
                width={8}
                style={{
                  fontSize: "medium",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "right",
                }}
              >
                <Label as="a" tag>
                  <Flag name={formatLowerCase(tenant)} /> {getMoney(element?.currencyISO)}{" "}
                  {formatNumber(element?.amount)}
                </Label>
              </Grid.Column>
            </Grid>
          </Accordion.Title>

          <Accordion.Content active={activeIndex === index} key={index}>
            {element.mandatoryFields.length > 0 &&
              element.mandatoryFields.map((mandatoryField, index) => (
                <InputDynamicValidationForm
                  key={mandatoryField.fieldName}
                  label={mandatoryField.fieldDsc}
                  messageError={mandatoryField.errormessage}
                  typeInput={"text"}
                  regex={mandatoryField.fieldRegex}
                  inputKey={mandatoryField.fieldName}
                  setValueParent={handleMandatoryField}
                />
              ))}
            {element.mandatoryFields.length <= 0 && (
              <Header size="tiny">
                You have selected a {element.providerName} as your supplier
              </Header>
            )}
          </Accordion.Content>
        </div>
      ))}
    </Accordion>
  );
};

export default AccordionProviderButton;
