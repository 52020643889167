import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Message, Header, Icon, HeaderContent, Dimmer, Loader } from 'semantic-ui-react';
import { endpoints, getAccessToken, getPaymentButtonStatus } from "../service/api";

const PrometeoWidget = () => {
  const [widget, setWidget] = useState(null);
  const [errorMessageShow, setErrorMessageShow] = useState(null);
  const [successMessageShow, setSuccessMessageShow] = useState(null);
  const [msgTypeError, setMsgTypeError] = useState(null);
  const [msgType, setMsgType] = useState(null);
  const [accessTokenState, setAccessTokenState] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRedirect, setIsRedirect] = React.useState(false);

  const [redirectUrl, setRedirectUrl] = useState(null);
  const params = useParams();
  const { intentId, tenant, publicKey } = params; 
  const widgetId = process.env.REACT_APP_PROMETEO_WIDGETID;


  const desactiveIsLogin = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const login = async () => {
    setIsLoading(true);
    const response = await getAccessToken(endpoints.getAccessToken);
    if (response.ok) {
      const responseData = await response.json();
      setAccessTokenState(responseData.accessToken);
      return responseData.accessToken;
    }
  };

  useEffect(() => {
    login().then((accessToken) => {
      desactiveIsLogin();
      if (!widget) {
        const initWidget = async () => {
          const Prometeo = window.Prometeo;
          const widgetInstance = Prometeo.init(widgetId, intentId);
          widgetInstance.on(Prometeo.Messaging.CLOSE, () => {
            setErrorMessageShow("Payment has not been processed");
            setMsgTypeError("ERROR");
          });
          widgetInstance.on(Prometeo.Messaging.ERROR, (error) => {
            setErrorMessageShow(error);
            setMsgTypeError("ERROR");
            startPolling(accessToken);
          });
          widgetInstance.on(Prometeo.Messaging.PAYMENT_SUCCESS, (payload) => {
            setSuccessMessageShow(`Payment Success - operation number: ${payload.operationNumber}`);
            setMsgType("MESSAGE");
            startPolling(accessToken);
          });

          widgetInstance.open({ allowedFeatureLevel: 2 }); // Abre el widget automáticamente
          setWidget(widgetInstance);
        };

        initWidget();
        }
    });
  }, [widget]);

  const startPolling = (accessToken) => {
    const intervalId = setInterval(async () => {
      try {
        getPaymentButtonStatus(accessToken, tenant, publicKey).then(
          async (response) => {
            if (response.status === 200) {
              const responseData = await response.data;
              if (responseData.urlRedirect && responseData.urlRedirect.trim()) {
                setRedirectUrl(responseData.urlRedirect);
                setIsRedirect(true);
                clearInterval(intervalId); // Detén el polling cuando se recibe la URL de redirección
              }
            }
          }
        );
      } catch (error) {
        console.error("Error fetching transaction status:", error);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  return (
    <div>
      {isLoading && (
        <Dimmer active>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {isRedirect && (
        <Dimmer active>
          <Loader size="massive">Redirecting to merchant website</Loader>
        </Dimmer>
      )}
      <Grid columns='three' stackable>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column />
          <Grid.Column>
            {(msgTypeError === 'ERROR' && msgType === 'MESSAGE') ? (
              <div className='not-found'>
                <Message floating>
                  <Header as='h1' icon textAlign='center' style={{ fontSize: 'xx-large' }}>
                    <Icon name='check circle outline' style={{ color: '#008000' }} />
                    <HeaderContent style={{ color: '#008000' }}>
                      <div>Payment has been processed</div>
                      <p style={{ fontSize: 'large', color: '#333333' }}>{errorMessageShow}</p>
                    </HeaderContent>
                  </Header>
                </Message>
              </div>
            ) : (
              (msgTypeError === 'ERROR') ? (
                <div className='not-found'>
                  <Message floating>
                    <Header as='h1' icon textAlign='center' style={{ fontSize: 'xx-large' }}>
                      <Icon name='warning circle' style={{ color: '#cc0101' }} />
                      <HeaderContent style={{ color: '#cc0101' }}>
                        <div>Payment processing error</div>
                        <p style={{ fontSize: 'large', color: '#333333' }}>{errorMessageShow}</p>
                      </HeaderContent>
                    </Header>
                  </Message>
                </div>
              ) : null
            )}
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default PrometeoWidget;