import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Message,
  Header,
  Icon,
  HeaderContent,
  Dimmer,
  Loader,
  Button,
} from "semantic-ui-react";
import { endpoints, getAccessToken, getPaymentButtonStatus } from "../../service/api";

function KhipuModal() {
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [paymentResult, setPaymentResult] = useState(null);
  const [msgType, setMsgType] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [resetPayment, setResetPayment] = useState(false);
  const [accessTokenState, setAccessTokenState] = useState("");

  const params = useParams();
  const { tenant, intentId, publicKey } = params;
  let khipu = null;

  const options = {
    mountElement: document.getElementById("khipu-web-root"),
    modal: true,
    modalOptions: {
      maxWidth: 450,
      maxHeight: 860,
    },
    options: {
      style: {
        primaryColor: "#8347AD",
        fontFamily: "Roboto",
      },
      skipExitPage: true,
    },
  };

  const desactiveIsLogin = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const login = async () => {
    setIsLoading(true);
    const response = await getAccessToken(endpoints.getAccessToken);
    if (response.ok) {
      const responseData = await response.json();
      setAccessTokenState(responseData.accessToken);
      return responseData.accessToken;
    }
  };

  const startPolling = (accessToken) => {
    const intervalId = setInterval(async () => {
      try {
        getPaymentButtonStatus(accessToken, tenant, publicKey).then(async (response) => {
          if (response.status === 200) {
            const responseData = await response.data;
            if (responseData.urlRedirect && responseData.urlRedirect.trim()) {
              setRedirectUrl(responseData.urlRedirect);
              setIsRedirect(true);
              clearInterval(intervalId);
            }
          }
        });
      } catch (error) {
        console.error("Error fetching transaction status:", error);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const callback = (result) => {
    setPaymentResult(result);
    setMsgType(result.result);
    setModalShow(false);
    if (
      result.failureReason === "SERVER_DISCONNECTED" ||
      result.failureReason === "USER_CANCELED"
    ) {
      setResetPayment(true);
    } else {
      login().then((accessToken) => {
        desactiveIsLogin();
        startPolling(accessToken);
        setPaymentStarted(false);
      });
    }
  };

  useEffect(() => {
    if (modalShow && !paymentStarted && intentId) {
      startPayment();
    }
  }, [paymentStarted, intentId]);

  const startPayment = () => {
    // eslint-disable-next-line no-undef
    khipu = new Khipu();
    khipu.startOperation(intentId, callback, options);
    setPaymentStarted(true);
  };

  const hanlderClickResetPayment = () => {
    window.location.reload(false);
  };

  return (
    <div>
      {modalShow && (
        <div id="khipu-web-root" style={{ display: paymentStarted ? "block" : "none" }}>
          {" "}
        </div>
      )}{" "}
      {!modalShow && isLoading && (
        <Dimmer active>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {!modalShow && isRedirect && (
        <Dimmer active>
          <Loader size="massive">Redirecting to merchant website</Loader>
        </Dimmer>
      )}
      <Grid columns="three" stackable>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column />
          <Grid.Column>
            {!modalShow && msgType === "OK" ? (
              <div className="not-found">
                <Message floating>
                  <Header as="h1" icon textAlign="center" style={{ fontSize: "xx-large" }}>
                    <Icon name="check circle outline" style={{ color: "#008000" }} />
                    <HeaderContent style={{ color: "#008000" }}>
                      <div>Payment has been processed</div>
                      {paymentResult && (
                        <div>
                          <p style={{ fontSize: "large", color: "#333333" }}>
                            <strong>Operation ID:</strong> {paymentResult?.operationId}
                          </p>
                          <p style={{ fontSize: "large", color: "#333333" }}>
                            En instantes será redirigido al sitio del comercio
                          </p>
                        </div>
                      )}
                    </HeaderContent>
                  </Header>
                </Message>
              </div>
            ) : !modalShow && msgType === "ERROR" ? (
              <div className="not-found">
                <Message floating>
                  <Header as="h1" icon textAlign="center" style={{ fontSize: "xx-large" }}>
                    <Icon name="warning circle" style={{ color: "#cc0101" }} />
                    <HeaderContent style={{ color: "#cc0101" }}>
                      <div>Resultado del Pago</div>
                      {paymentResult && (
                        <div>
                          <p style={{ fontSize: "large", color: "#333333" }}>
                            <strong>Operation ID:</strong> {paymentResult?.operationId}
                          </p>
                        </div>
                      )}
                      {resetPayment && (
                        <Button onClick={hanlderClickResetPayment} color="orange">
                          Reintentar
                        </Button>
                      )}
                    </HeaderContent>
                  </Header>
                </Message>
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default KhipuModal;
