import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePayinButton from "../component/HomePayinButton";
import RedirectPaymentButtonComponent from "../component/RedirectPaymentButtonComponent";
import PrometeoWidget from "../component/PrometeoWidget";
import NotFound from "../component/NotFound";
import ProntoPagaHome from "../component/ProntoPaga/ProntoPagaHome";
import CallBackComponent from "../component/ProntoPaga/CallBackComponent";
import KhipuModal from "../component/Khipu/KhipuModal";
import MonnetVoucherHome from "../component/Monnet/MonnetVoucherHome";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/:tenant/:publicKey" element={<HomePayinButton />} />
        <Route
          path="/paymentInfo/:tenant/:publicKey"
          element={<RedirectPaymentButtonComponent />}
        />
        <Route path="/prometeo/:tenant/:intentId/:publicKey" element={<PrometeoWidget />} />
        <Route path="/prontopaga/:tenant/:publicKey" element={<ProntoPagaHome />} />
        <Route
          path="/prontopaga/:tenant/callback/:status/:publicKey"
          element={<CallBackComponent />}
        />
        <Route path="/khipu/:tenant/:intentId/:publicKey" element={<KhipuModal />} />
        <Route path="/monnet/:tenant/:publicKey" element={<MonnetVoucherHome />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
