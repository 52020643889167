import "./App.css";
import Footer from "./component/Footer";
import AppRoutes from "./route/AppRoutes";

function App() {
  return (
    <div className="content-app">
      <div className="background">
        <AppRoutes />
      </div>
      <Footer />
    </div>
  );
}

export default App;
