import React, { useState } from 'react';
import './payment-method-selector.css'; // Estilo CSS para el componente
import { GridRow, GridColumn, Grid } from 'semantic-ui-react'
import { CardHeader, CardContent, Card, Image } from 'semantic-ui-react'

const PaymentMethodSelector = ({ paymentMethods, onConfirm }) => {
    const [selectedMethod, setSelectedMethod] = useState(null);

    const handleSelect = (method) => {
        setSelectedMethod(method);
        onConfirm(method); // Llamar a la función onConfirm con el método seleccionado
    };

    return (
        <div className="payment-method-selector">
            <Grid stackable>
                <GridRow columns={3} only='computer'>
                    {paymentMethods && paymentMethods.map((method) => (
                        <GridColumn key={method.id}>
                            <div className={`payment-method ${selectedMethod?.method === method?.method ? 'selected' : ''}`} onClick={() => handleSelect(method)}>
                                <input
                                    type="radio"
                                    id={method.id}
                                    name="paymentMethod"
                                    value={method.id}
                                    checked={selectedMethod === method}
                                    onChange={() => {}}
                                />
                                <label htmlFor={method.id}>
                                    <Card className={`payment-card ${selectedMethod === method ? 'selected' : ''}`}>
                                        <Image src={method.logo} alt={method.name} wrapped ui={false} />
                                        <CardContent>
                                            <CardHeader>{method.name}</CardHeader>
                                        </CardContent>
                                    </Card>
                                </label>
                            </div>
                        </GridColumn>
                    ))}
                </GridRow>
                <GridRow columns={1} only='tablet'>
                    {paymentMethods && paymentMethods.map((method) => (
                        <GridColumn key={method.id}>
                            <div className={`payment-method ${selectedMethod?.method === method?.method ? 'selected' : ''}`} onClick={() => handleSelect(method)}>
                                <input
                                    type="radio"
                                    id={method.id}
                                    name="paymentMethod"
                                    value={method.id}
                                    checked={selectedMethod === method}
                                    onChange={() => {}}
                                />
                                <label htmlFor={method.id}>
                                    <Card className={`payment-card ${selectedMethod === method ? 'selected' : ''}`}>
                                        <Image src={method.logo} alt={method.name} wrapped ui={false} />
                                        <CardContent>
                                            <CardHeader>{method.name}</CardHeader>
                                        </CardContent>
                                    </Card>
                                </label>
                            </div>
                        </GridColumn>
                    ))}
                </GridRow>
                <GridRow columns={1} only='mobile'>
                    {paymentMethods && paymentMethods.map((method) => (
                        <GridColumn key={method.id}>
                            <div className={`payment-method ${selectedMethod?.method === method?.method ? 'selected' : ''}`} onClick={() => handleSelect(method)}>
                                <input
                                    type="radio"
                                    id={method.id}
                                    name="paymentMethod"
                                    value={method.id}
                                    checked={selectedMethod === method}
                                    onChange={() => {}}
                                />
                                <label htmlFor={method.id}>
                                    <Card className={`payment-card ${selectedMethod === method ? 'selected' : ''}`}>
                                        <Image src={method.logo} alt={method.name} wrapped ui={false} />
                                        <CardContent>
                                            <CardHeader>{method.name}</CardHeader>
                                        </CardContent>
                                    </Card>
                                </label>
                            </div>
                        </GridColumn>
                    ))}
                </GridRow>
            </Grid>
        </div>
    );
};

export default PaymentMethodSelector;