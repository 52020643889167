import { useEffect, useState } from "react";

const ExpirationCountdown = ({ expiration }) => {
  const parseDate = (dateString) => {
    return new Date(dateString?.replace(" ", "T"));
  };

  const formatExpirationDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const datePart = new Intl.DateTimeFormat("es-ES", options).format(date);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${datePart} - ${hours}:${minutes} hrs.`;
  };
  const calculateTimeLeft = () => {
    const expirationDate = parseDate(expiration);
    const now = new Date();
    const difference = expirationDate - now;

    if (difference <= 0) {
      return { expired: true };
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { expired: false, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const expirationDateFormatted = formatExpirationDate(parseDate(expiration));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [expiration]);

  return (
    <div style={{ textAlign: "center" }}>
      {timeLeft.expired ? (
        <p style={{ fontSize: "1.2em", fontWeight: "bold", color: "red", margin: 0 }}>EXPIRADO</p>
      ) : (
        <p style={{ fontSize: "1.2em", fontWeight: "bold", margin: 0 }}>
          {`${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}
        </p>
      )}
      <p style={{ fontSize: "1em", marginTop: "5px", color: "#555" }}>
        Pagar antes del <strong>{expirationDateFormatted}</strong>
      </p>
    </div>
  );
};

export default ExpirationCountdown;
