import React from 'react';
import {Formik} from 'formik';
import {Grid, Input, Label} from "semantic-ui-react";

const InputDynamicValidationForm = ({label, messageError, typeInput, regex, inputKey, setValueParent}) => (
    <div>
        <Formik
            initialValues={{inputDynamicValue: ''}}
            validate={values => {
                const errors = {};
                if (!values.inputDynamicValue) {
                    errors.inputDynamicValue = 'Required';
                } else if (
                    !new RegExp(regex).test(values.inputDynamicValue)
                ) {
                    errors.inputDynamicValue = messageError;
                }
                if(errors.inputDynamicValue === undefined){
                    setValueParent(inputKey, values.inputDynamicValue)
                }
                return errors;
            }
        }
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Grid columns={2} divided>
                        <Grid.Row stretched>
                            <Grid.Column>
                                <label className="label-text">{label}</label>
                                <Input
                                    placeholder={label}
                                    type={typeInput}
                                    name="inputDynamicValue"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={errors?.inputDynamicValue && touched?.inputDynamicValue && errors?.inputDynamicValue}
                                />
                                {errors?.inputDynamicValue && touched?.inputDynamicValue && errors?.inputDynamicValue && (
                                    <Label basic color='red' pointing>
                                        {messageError}
                                    </Label>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </form>
                )}
        </Formik>
    </div>
);


export default InputDynamicValidationForm;