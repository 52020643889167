import CopyToClipboard from "react-copy-to-clipboard";
import React from "react";
import { Container, Card, Button, Grid, GridColumn } from "semantic-ui-react";
import ExpirationCountdown from "./ExpirationCountdown";

const PaymentInfo = ({ experienceVoucher }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  return (
    <Grid
      columns="equal"
      style={{
        background: "#e5e5e5",
        borderRadius: "15px",
        marginLeft: "30px",
        marginRight: "30px",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <GridColumn style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h3>Monto a pagar</h3>
        <p style={{ fontSize: "1.2em", fontWeight: "bold", margin: 0 }}>
          {experienceVoucher?.currencyISO} {experienceVoucher?.amount}
        </p>
      </GridColumn>

      <GridColumn
        width={8}
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <h3>Código de Pago</h3>
        <p style={{ fontSize: "1.4em", fontWeight: "bold", color: "#182a53", margin: 0 }}>
          {experienceVoucher?.paymentCode}
        </p>
        <br></br>
        <CopyToClipboard text={experienceVoucher?.paymentCode} onCopy={() => setIsCopied(true)}>
          <Button
            icon="copy"
            basic
            color="blue"
            content={isCopied ? "Copiado!" : "Copiar"}
            style={{ color: "#182a53" }}
          />
        </CopyToClipboard>
      </GridColumn>

      <GridColumn style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <h3>Vence en</h3>
        <ExpirationCountdown expiration={experienceVoucher?.expiration}></ExpirationCountdown>
      </GridColumn>
    </Grid>
  );
};

export default PaymentInfo;
