import React, { useEffect } from 'react';
import {useParams} from "react-router-dom";
import PaymentMethodSelector from './PaymentMethodSelector';
import { Grid, Message, Icon, Header, HeaderContent, Dimmer, Loader, Segment, TableRow, TableCell, TableBody, Table, Button, Card, GridColumn } from 'semantic-ui-react';
import getSymbolFromCurrency from 'currency-symbol-map'
import { getSelectPaymentMethods, putPaymentProvider } from './apiProntoPaga';
import NextStepComponent from '../NextStepComponent';
import { endpoints, getAccessToken } from '../../service/api';
//import NextStepComponent from "./NextStepComponent";


const ProntoPagaHome = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isRedirectBool, setIsRedirectBool] = React.useState(false);
    const [succesMessage, setSuccesMessage] = React.useState(false);
    const [errorMessageShow, setErrorMessageShow] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [typeNextStep, setTypeNextStep] = React.useState('');
    const [showUrl, setShowUrl] = React.useState('');
    const [isSendPut, setIsSendPut] = React.useState(false);
    const [paymentMethodsObj, setPaymentMethodsObj] = React.useState(null);
    const [selectedMethod, setSelectedMethod] = React.useState(null);
    const [accessTokenState, setAccessTokenState] = React.useState("");

    const params = useParams();
    const { tenant, publicKey } = params;

    const EMBEDDED = 'EMBEDDED';
    const REDIRECT = 'REDIRECT';
    const MESSAGE = 'MESSAGE';
    const ERROR = 'ERROR';

    const desactiveIsLogin = () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        
        if (isRedirectBool) {
            setTimeout(() => {
                setIsRedirectBool(false);
            }, 1000);
        }
    };

    const getPaymentMethods = async (accessToken) => {
        const response = await getSelectPaymentMethods(accessToken, tenant, publicKey);
        if (response.status === 200) {
            const responseData = await response.data;
            desactiveIsLogin();
            if (responseData?.responseCode && responseData.responseCode !== 1000) {
                setErrorMessage(responseData.responseMessage)
                setErrorMessageShow(true)
                setTypeNextStep(ERROR)
            }
            else if(responseData !== null) {
                setPaymentMethodsObj(responseData);
            }
        } else {
            setErrorMessageShow(true)
            desactiveIsLogin()
            setTypeNextStep(ERROR)
        }
    };

    const putPayment = async () => {
        if (selectedMethod && selectedMethod?.method) {
            const response = await putPaymentProvider(accessTokenState, publicKey, tenant, selectedMethod.method);
            debugger;
            if (response.status === 200) {
                setIsRedirectBool(true);
                const responseData = await response.data;
                if(responseData.responseCode === 1000){
                    if(responseData.structure != null && responseData.structure.url != null){
                        if(responseData.structure.url.mode === REDIRECT){
                            setShowUrl(responseData.structure.url.url)
                            setTypeNextStep(REDIRECT)
                            desactiveIsLogin()
                        }
                        else{
                            setTypeNextStep(MESSAGE)
                            desactiveIsLogin()
                        }
                    }
                    setSuccesMessage(true)
                    desactiveIsLogin()
                } else {
                    setSuccesMessage(false)
                    setTypeNextStep(ERROR)
                    desactiveIsLogin()
                }
            } else {
                setIsLoading(true);
                setSuccesMessage(false)
                setTypeNextStep(ERROR)
                desactiveIsLogin()
            }
        }
        setIsLoading(false);
    };

    const login = async () => {
        const response = await getAccessToken(endpoints.getAccessToken);
        if (response.ok) {
          const responseData = await response.json();
          setAccessTokenState(responseData.accessToken);
          return responseData.accessToken;
        }
      };

    useEffect(() => {
        if(paymentMethodsObj === null){
            setIsLoading(true);
            login().then((accessToken) => getPaymentMethods(accessToken));
            //getPaymentMethods("");
        }
    }, []);

    const handleConfirm = (selectedMethod) => {
        setIsSendPut(true);
        setSelectedMethod(selectedMethod);
    };

    const formatNumber = (amount) => {
        if (amount != null && amount !== '') {
            return Number(amount).toFixed(2);
        }
        return Number("0").toFixed(2);
    }

    function getMoney(iso2) {
        const symbol = getSymbolFromCurrency(iso2);
        return symbol || iso2; 
    }


    return (
        <div>
            {isLoading && (
                <Dimmer active>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            )}
            {isRedirectBool && (
                <Dimmer active>
                    <Loader size='massive'>You will be redirected to a secure page.</Loader>
                </Dimmer>
                
            )}
            {!succesMessage && !errorMessageShow && (                
                <>
                    <br></br>
                    <br></br>
                    <Grid columns='equal' reversed='vertically stackable'>
                        <GridColumn>
                                              
                        </GridColumn>
                        <GridColumn width={8}>
                            <div class="grid-container">
                                <img class="logo centrada-horizontalmente" src="https://www.morepaymentevolution.com/src/img/logo.svg" alt="More Payment Evolution" style={{maxWidth: '40%'}} ></img>
                            </div>
                            <h1>Select Payment Method</h1>
                        </GridColumn>

                        <GridColumn>
                        </GridColumn>
                    </Grid>
                    <Grid columns='equal' reversed='vertically stackable'>
                        <GridColumn>
                                              
                        </GridColumn>
                        <GridColumn width={8}>
                            <Grid.Column/>
                                <Grid.Column>
                                    <PaymentMethodSelector paymentMethods={paymentMethodsObj?.paymentMethods} onConfirm={handleConfirm} />
                                </Grid.Column>
                            <Grid.Column/>

                        </GridColumn>
                        <GridColumn width={4}>
                            {paymentMethodsObj && (
                                <Grid.Column width={2}>
                                    <Card fluid >
                                        <Table basic='very' style={{ paddingLeft: '10px', paddingRight: '10px' }} unstackable>
                                            <TableBody >
                                                <TableRow>
                                                    <TableCell><Header as='h4'> Payment Method</Header></TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>
                                                        {selectedMethod && selectedMethod.name ? selectedMethod.name : "No Payment Method Selected"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell><Header as='h4'> Send Amount ({paymentMethodsObj?.currency})</Header></TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>{getMoney(paymentMethodsObj?.currency)} {formatNumber(paymentMethodsObj?.amount)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        
                                    </Card>
                                    <Grid columns='equal'>
                                        <Grid.Column style={{textAlign: "rigth"}}>
                                            <Button animated onClick={()=> putPayment()} positive={isSendPut} disabled={!isSendPut}>
                                                <Button.Content visible>NEXT STEP </Button.Content > 
                                                <Button.Content hidden>
                                                    <Icon name='arrow right'/>
                                                </Button.Content>
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            )}
                        </GridColumn>
                        <GridColumn width={1}>

                        </GridColumn>
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                </>
            )}

            {succesMessage && (
                <NextStepComponent
                    message={errorMessage} 
                    typeMessage={typeNextStep}
                    url={showUrl}
                    >
                </NextStepComponent>

             )}
             {errorMessageShow && (
                <NextStepComponent
                    message={errorMessage} 
                    typeMessage={typeNextStep}>
                </NextStepComponent>
             )}
            
        </div>

    );
};

export default ProntoPagaHome;
