import './App.css';
import Footer from './component/Footer';
import AppRoutes from "./route/AppRoutes";

function App() {
  return (
    <div className='content-app background'>
        <AppRoutes></AppRoutes>
        <Footer></Footer>
    </div>
  );
}

export default App;
