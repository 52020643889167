import axios from "axios";

export const currentHost = `${window.location.protocol}//${window.location.hostname}`;
export const API_OAUTH_CLIENT_ID = process.env.REACT_APP_LOGIN_PAYIN_CLIENT_ID;
export const API_OAUTH_CLIENT_SECRET = process.env.REACT_APP_LOGIN_PAYIN_CLIENT_SECRET;

export const endpoints = {
  getPaymentInfo: "/paymentButton/",
  putPayment: "/payment/",
  getAccessToken: "/getAccessToken/",
  getPaymentButtonInfo: "/paymentButton/getTransactionInfo",
  getPaymentButtonStatus: "/paymentButton/getTransactionStatus",
};

export function getApiUrl() {
  if (process.env.REACT_APP_USE_PROXY === "true") {
    return "/api";
  }
  return process.env.REACT_APP_BACKEND;
}

export function getApiUrlLogin() {
  if (process.env.REACT_APP_USE_PROXY === "true") {
    return "/login";
  }
  return process.env.REACT_API_LOGIN;
}

export async function getPaymentInfo(endpoint, localAccessToken, publicKey, tenant) {
  const opts = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": `${currentHost}`,
      "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localAccessToken}`,
      "X-TENANT-ID": `${tenant}`,
    },
    mode: "cors",
  };
  return fetch(
    `${getApiUrl()}${endpoint}?publicKey=${encodeURIComponent(publicKey)}`,
    opts
  );
}

export async function putPaymentProvider(
  endpoint,
  localAccessToken,
  publicKey,
  body,
  tenant
) {
  const opts = {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": `${currentHost}`,
      "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localAccessToken}`,
      "X-TENANT-ID": `${tenant}`,
    },
    mode: "cors",
    body: JSON.stringify(body),
  };
  return fetch(
    `${getApiUrl()}${endpoint}?publicKey=${encodeURIComponent(publicKey)}`,
    opts
  );
}

export async function getAccessToken(endpoint) {
  const opts = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": `${currentHost}`,
      "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
    },
    mode: "cors",
  };
  return fetch(
    `${getApiUrlLogin()}/security`, opts);
}

export async function getPaymentButtonInfo(
  localAccessToken,
  tenant,
  publicKey
) {
  const opts = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": `${currentHost}`,
      "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${localAccessToken}`,
      "X-TENANT-ID": `${tenant}`,
    },
    mode: "cors",
  };

  return fetch(
    `${getApiUrl()}${
      endpoints.getPaymentButtonInfo
    }?publicKey=${encodeURIComponent(publicKey)}`,
    opts
  );
}

export async function getPaymentButtonStatus(
  localAccessToken,
  tenant,
  publicKey
) {
  let customHeaders = {
    "Access-Control-Allow-Origin": `${currentHost}`,
    "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
    "X-TENANT-ID": `${tenant}`,
    "Content-Type": "application/json",
    Authorization: `Bearer ${localAccessToken}`,
  };

  return await axios.get(
    `${getApiUrl()}${
      endpoints.getPaymentButtonStatus
    }?publicKey=${encodeURIComponent(publicKey)}`,
    { headers: customHeaders }
  );
}
