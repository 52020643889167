//{{baseUrl}}/paymentButton/getSelectPaymentMethods/?publicKey=f294947c-42a0-4ac8-a22e-963a3f06
//https://paymentbutton-dev.morepaymentevolution.com/prontopaga/CL/cbdd9907-5fad-4cd7-8f7f-9c0cb427
//PUT {{baseUrl}}/prontoPaga/completeAndSendPayment/?publicKey=cbdd9907-5fad-4cd7-8f7f-9c0cb427&method=bemovil_payment
import axios from "axios";
import { getApiUrl } from "../../service/api";

export const currentHost = `${window.location.protocol}//${window.location.hostname}`;
export const API_OAUTH_CLIENT_ID = process.env.REACT_APP_LOGIN_PAYIN_CLIENT_ID;
export const API_OAUTH_CLIENT_SECRET = process.env.REACT_APP_LOGIN_PAYIN_CLIENT_SECRET;


export async function getSelectPaymentMethods(
    localAccessToken,
    tenant,
    publicKey
  ) {
    let customHeaders = {
      "Access-Control-Allow-Origin": `${currentHost}`,
      "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
      "X-TENANT-ID": `${tenant}`,
      "Content-Type": "application/json",
      Authorization: `Bearer ${localAccessToken}`,
    };
  
    return await axios.get(
        `${getApiUrl()}/paymentButton/getSelectPaymentMethods/?publicKey=${encodeURIComponent(publicKey)}`,
        { headers: customHeaders }
    );
}
  
export async function putPaymentProvider(
    localAccessToken,
    publicKey,
    tenant,
    method
  ) {
    let customHeaders = {
        "Access-Control-Allow-Origin": `${currentHost}`,
        "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
        Authorization: `Bearer ${localAccessToken}`,
        "X-TENANT-ID": `${tenant}`
    };
    return axios.put(`${getApiUrl()}/prontoPaga/completeAndSendPayment/?publicKey=${encodeURIComponent(publicKey)}&paymentMethod=${encodeURIComponent(method)}`,
        null,
        { headers: customHeaders })
  }