import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Dimmer,
  Grid,
  GridColumn,
  Header,
  HeaderContent,
  Icon,
  Loader,
  Message,
} from "semantic-ui-react";
import PaymentMethodSelector from "./PaymentMethodSelector";
import "./css/monnet.css";
import PaymentInfo from "./PaymentInfo";
import { getPaymentInfo, getPaymentStatus } from "./api/monnet";
import { endpoints, getAccessToken } from "../../service/api";
import { SubStatusEnum } from "../../model/subStatusEnum";
import { StatusEnum } from "../../model/statusEnum";

const MonnetVoucherHome = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [experienceVoucher, setExperienceVoucher] = React.useState(null);
  const [accessTokenState, setAccessTokenState] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  const params = useParams();
  const { tenant, publicKey } = params;

  const desactiveIsLogin = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const getPaymentStatusData = async (accessToken) => {
    getPaymentStatus(accessToken, tenant, publicKey)
      .then((response) => {
        const paymentStatusData = response.data;
        if (
          paymentStatusData.statusCode == StatusEnum.ERROR &&
          paymentStatusData.subStatusCode == SubStatusEnum.EXPIRED
        ) {
          setErrorMessage("Payment link has expired");
          setIsLoading(false);
        } else {
          getPaymentMethods(accessToken);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("An error ocurred while getting payment information.");
        setIsLoading(false);
      });
  };

  const getPaymentMethods = async (accessToken) => {
    try {
      const response = await getPaymentInfo(accessToken, tenant, publicKey);
      if (response.status === 200) {
        const responseData = await response.data;
        setExperienceVoucher(responseData);
      } else if (response.status === 404) {
        setErrorMessage("El pago no existe. Verifique los datos e intente nuevamente.");
      } else {
        setErrorMessage("Ocurrió un error al obtener la información del pago.");
      }
    } catch (error) {
      setErrorMessage("El pago no existe. Verifique los datos e intente nuevamente.");
    } finally {
      desactiveIsLogin();
    }
  };
  const login = async () => {
    const response = await getAccessToken(endpoints.getAccessToken);
    if (response.ok) {
      const responseData = await response.json();
      setAccessTokenState(responseData.accessToken);
      return responseData.accessToken;
    }
  };

  useEffect(() => {
    if (experienceVoucher === null) {
      setIsLoading(true);
      login().then((accessToken) => getPaymentStatusData(accessToken));
    }
  }, []);

  if (isLoading) {
    return (
      <Dimmer active>
        <Loader size="massive">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      {isLoading && (
        <Dimmer active>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}

      {errorMessage ? (
        <>
          <div className="not-found">
            <Message floating>
              <Header as="h1" icon textAlign="center" style={{ fontSize: "xx-large" }}>
                <Icon name="warning circle" style={{ color: "#cc0101" }} />
                <HeaderContent style={{ color: "#cc0101" }}>
                  <div>Payment processing error</div>
                  <p style={{ fontSize: "large", color: "#333333" }}>{errorMessage}</p>
                </HeaderContent>
              </Header>
            </Message>
          </div>
        </>
      ) : (
        <div style={{ marginBottom: "100px" }}>
          <br></br>
          <br></br>
          <Grid columns="equal" reversed="vertically stackable">
            <GridColumn></GridColumn>
            <GridColumn width={8}>
              <div class="grid-container">
                <img
                  class="logo centrada-horizontalmente"
                  src="https://www.morepaymentevolution.com/src/img/logo.svg"
                  alt="More Payment Evolution"
                  style={{ maxWidth: "20%" }}
                ></img>
              </div>
            </GridColumn>
            <GridColumn></GridColumn>
          </Grid>
          <Grid columns="equal" reversed="vertically stackable">
            <GridColumn></GridColumn>
            <GridColumn
              width={8}
              style={{
                background: "#ffffff",
                paddingTop: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  background: "#182a53",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <h1 style={{ textAlign: "center", color: "white" }}>Informacion del Pago</h1>
              </div>
              <br></br>
              <br></br>

              {experienceVoucher && (
                <>
                  <PaymentInfo experienceVoucher={experienceVoucher}></PaymentInfo>
                  <Container style={{ padding: "20px", maxWidth: "100%" }}>
                    <PaymentMethodSelector
                      bankList={experienceVoucher?.voucherConfig?.bankList}
                    ></PaymentMethodSelector>
                  </Container>
                </>
              )}
            </GridColumn>
            <GridColumn></GridColumn>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default MonnetVoucherHome;
