import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePayinButton from "../component/HomePayinButton";
import RedirectPaymentButtonComponent from "../component/RedirectPaymentButtonComponent";
import PrometeoWidget from "../component/PrometeoWidget";
import NotFound from "../component/NotFound";
import ProntoPagaHome from "../component/ProntoPaga/ProntoPagaHome";
import CallBackComponent from "../component/ProntoPaga/CallBackComponent";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/:tenant/:publicKey" element={<HomePayinButton />} />
        <Route
          path="/paymentInfo/:tenant/:publicKey"
          element={<RedirectPaymentButtonComponent />}
        />
        <Route
          path="/prometeo/:tenant/:intentId/:publicKey"
          element={<PrometeoWidget />}
        />
        <Route
          path="/prontopaga/:tenant/:publicKey"
          element={<ProntoPagaHome />}
        />
        <Route
          path="/prontopaga/:tenant/callback/:status/:publicKey"
          element={<CallBackComponent />}
        />
        <Route
          path="*"
          element={ <NotFound /> }
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;