import React, {useEffect} from 'react'
import { Grid, Header, Dimmer, Loader, TableRow, TableCell, TableBody,Table, Segment } from 'semantic-ui-react'
import AccordionProviderButton from "./AccordionProviderButton";
import {Icon, Button, Card} from 'semantic-ui-react'
import {useParams} from "react-router-dom";
import {endpoints, getPaymentInfo, putPaymentProvider, getAccessToken} from "../service/api";
import NextStepComponent from "./NextStepComponent";
import NotificationMessage from "./NotificationMessage";
import getSymbolFromCurrency from 'currency-symbol-map'
import Footer from './Footer';

const HomePayinButton = () => {
    const params = useParams();
    const [providerList, setProviderList] = React.useState([]);
    const [succesMessage, setSuccesMessage] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [typeNextStep, setTypeNextStep] = React.useState('');
    const [accessTokenState, setAccessTokenState] = React.useState('');
    const [showUrl, setShowUrl] = React.useState('');
    const [errorMessageShow, setErrorMessageShow] = React.useState(false);
    const [isShowNotification, setShowNotification] = React.useState(false);
    const [isSendPut, setIsSendPut] = React.useState(false); 
    const [isLoading, setIsLoading] = React.useState(false);
    const [mandatoryFieldMap, setMandatoryFieldMap] = React.useState(new Map());
    const [amount, setAmount] = React.useState(0);
    const [currency, setCurrency] = React.useState('');
    const [sendAmount, setSendAmount] = React.useState('');
    const [sendCurrency, setSendCurrency] = React.useState('');

    const { tenant, publicKey } = params;

    const EMBEDDED = 'EMBEDDED';
    const REDIRECT = 'REDIRECT';
    const MESSAGE = 'MESSAGE';
    const ERROR = 'ERROR';

    const desactiveIsLogin = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
      };
    
    const setAmountAndCurrency = (amount, currency) => {
        setSendAmount(amount);
        setSendCurrency(currency);
    }

    const login = async () => {
        const response = await getAccessToken(endpoints.getAccessToken);
        if (response.ok) {
            const responseData = await response.json();
            setAccessTokenState(responseData.accessToken)
            return responseData.accessToken;
        }
    };
        
    const checkMandatoryFieldMap = () => {
        if(providerList.length > 0){
            const searchIndex = providerList.findIndex((element) => element.aggregatorId === mandatoryFieldMap.get('aggregatorId'));
            if(providerList[searchIndex].mandatoryFields.length>0 ){
                if(mandatoryFieldMap.size === providerList[searchIndex].mandatoryFields.length + 1) {
                    setShowNotification(false)
                    setIsSendPut(true)
                }else{
                    setShowNotification(true)
                    setIsSendPut(false)
                }
            }
            else if(providerList[searchIndex].mandatoryFields.length <= 0 && mandatoryFieldMap.size > 0 ){
                setShowNotification(false)
                setIsSendPut(true)
            }
            else{
                setShowNotification(true)
                setIsSendPut(false)
            }
            setAmountAndCurrency(providerList[searchIndex].amount, providerList[searchIndex].currencyISO)
        }  
    }

    const handlerClickMandatoryFieldMap = () => {
        checkMandatoryFieldMap();
        if(isSendPut){
            setIsLoading(true);
            putPayment(publicKey, Object.fromEntries(mandatoryFieldMap));
        }
    }
    const getPayment = async (publicKey, accessToken) => {
        const response = await getPaymentInfo(endpoints.getPaymentInfo, accessToken, publicKey, tenant);
        if (response.ok) {
            const responseData = await response.json();
            desactiveIsLogin();
            if(responseData.responseCode !== 1000 ){
                setErrorMessage(responseData.responseMessage)
                setErrorMessageShow(true)
                setTypeNextStep(ERROR)
            }
            else if(responseData.aggregators !== null) {
                const listOrder = orderDescByAmount(responseData.aggregators);
                setProviderList(listOrder);
                if(listOrder.length > 0){
                    setAmountAndCurrency(listOrder[0].amount, listOrder[0].currencyISO)
                }
            }
            setAmount(responseData.amount);
            setCurrency(responseData.currency);
        } else {
            setErrorMessageShow(true)
            desactiveIsLogin()
            setTypeNextStep(ERROR)
        }
    };

    const putPayment = async (publicKey, body) => {
        const objSend = {
            ...body,
            providerId: body.aggregatorId,
          };
          delete objSend.aggregatorId;

        const response = await putPaymentProvider(endpoints.putPayment, accessTokenState, publicKey, objSend, tenant);
        if (response.ok) {
            const responseData = await response.json();
            desactiveIsLogin()
            if(responseData.responseCode === 1000){
                if(responseData.structure != null && responseData.structure.url != null){
                    if(responseData.structure.url.mode === EMBEDDED){
                        setShowUrl(responseData.structure.url.url)
                        setTypeNextStep(EMBEDDED);
                    }
                    else if(responseData.structure.url.mode === REDIRECT){
                        setShowUrl(responseData.structure.url.url)
                        setTypeNextStep(REDIRECT)
                    }
                    else{
                        setTypeNextStep(MESSAGE)
                    }
                } else {
                    setErrorMessage(responseData.structure.html_Instrucctions);
                    setTypeNextStep(MESSAGE)
                }
                setSuccesMessage(true)               
            } else {
                setSuccesMessage(false)
                setTypeNextStep(ERROR)
            }
        } else {
            setSuccesMessage(false)
            setTypeNextStep(ERROR)
            desactiveIsLogin()
        }
    };

    useEffect(() => {
        if(providerList.length <= 0){
            setIsLoading(true);
            login().then((accessToken) => getPayment(publicKey, accessToken));
        }
    }, []);

    const formatNumber = (amount) => {
        if (amount != null && amount !== '') {
            return Number(amount).toFixed(2);
        }
        return Number("0").toFixed(2);
    }

    function getMoney(iso2) {
        const symbol = getSymbolFromCurrency(iso2);
        return symbol || iso2; 
    }

    function orderDescByAmount(list) {
        return list.sort((a, b) => a.amount - b.amount);

    }

    return (
        <div>
            {isLoading && (
                <Dimmer active>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            )}
            {!succesMessage && !errorMessageShow && (
                <Grid columns='three' reversed='vertically stackable'>
                <Grid.Row/>
                <Grid.Row>
                    <Grid.Column/>
                    <Grid.Column>
                        <div class="grid-container">
                            <img class="logo centrada-horizontalmente" src="https://www.morepaymentevolution.com/src/img/logo.svg" alt="More Payment Evolution" style={{maxWidth: '40%'}} ></img>
                        </div>
                    </Grid.Column>
                    <Grid.Column/>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column/>
                    <Grid.Column>
                        <AccordionProviderButton
                            providerList={providerList}
                            mandatoryFieldMap={mandatoryFieldMap}
                            setMandatoryFieldMap={setMandatoryFieldMap}
                            checkMandatoryFieldMap={checkMandatoryFieldMap}
                            tenant={tenant}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Card fluid >
                            <Table basic='very' style={{paddingLeft: '10px', paddingRight: '10px'}} unstackable>
                                <TableBody >
                                    <TableRow>
                                        <TableCell><Header as='h4'> Collect Amount ({currency})</Header></TableCell>
                                        <TableCell style={{textAlign: 'right'}}>{getMoney(currency)} {formatNumber(amount)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Header as='h4'> Send Amount ({sendCurrency})</Header></TableCell>
                                        <TableCell style={{textAlign: 'right'}}>{getMoney(sendCurrency)} {formatNumber(sendAmount)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Card>
                        <Grid columns='equal'>
                            <Grid.Column style={{textAlign: "center"}}>
                                <Button animated onClick={handlerClickMandatoryFieldMap} positive={isSendPut}>
                                    <Button.Content visible>CONFIRM PAYMENT</Button.Content > 
                                    <Button.Content hidden>
                                        <Icon name='arrow right'/>
                                    </Button.Content>
                                </Button>
                            </Grid.Column>
                        </Grid>
                        <Grid columns='equal'>
                            <Grid.Column style={{textAlign: "center"}}>
                            <Segment key={'mini'} size={'mini'} style={{backgroundColor: "#e5e5e5"}}>
                                By clicking 'Confirm payment', you agree to the terms and conditions of service. If you have any problems with your payment, please visit our Customer Support Portal.
                            </Segment>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column/>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column/>
                    <Grid.Column>
                        {isShowNotification && (
                            <NotificationMessage 
                                setShowNotification={setShowNotification}>
                            </NotificationMessage>
                            )
                        }
                    </Grid.Column>
                    <Grid.Column/>
                </Grid.Row>
            </Grid>
            )}
            {succesMessage && (
                <NextStepComponent 
                    message={errorMessage} 
                    typeMessage={typeNextStep}
                    url={showUrl}
                    >
                </NextStepComponent>

             )}
             {errorMessageShow && (
                <NextStepComponent
                    message={errorMessage} 
                    typeMessage={typeNextStep}>
                </NextStepComponent>
             )}
        </div>
    )
}

export default HomePayinButton