import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>Powered by</p>
        <a href="https://www.morepaymentevolution.com/es/index.html#home" target="_blank"  rel="noopener noreferrer">
          <img class="logo" src="https://www.morepaymentevolution.com/src/img/logo-blanco.svg" alt="More Payment Evolution" style={{maxWidth: '10%'}}></img>
        </a>
      </div>
    </footer>
  );
}

export default Footer;