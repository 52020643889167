import React, { useEffect } from 'react';
import {useParams} from "react-router-dom";
import { Dimmer, Loader } from 'semantic-ui-react';
import NextStepComponent from '../NextStepComponent';
import { endpoints, getAccessToken, getPaymentButtonStatus } from '../../service/api';

const CallBackComponent = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [succesMessage, setSuccesMessage] = React.useState(false);
    const [errorMessageShow, setErrorMessageShow] = React.useState(false);
    const [messageShow, setMessageShow] = React.useState('');
    const [typeNextStep, setTypeNextStep] = React.useState('');
    const [isRedirect, setIsRedirect] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState(null);

    const params = useParams();
    const { status, tenant, publicKey } = params;

    const MESSAGE = 'MESSAGE';
    const ERROR = 'ERROR';

    const desactiveIsLogin = () => {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
    };

    const login = async () => {
        setIsLoading(true);
        const response = await getAccessToken(endpoints.getAccessToken);
        if (response.ok) {
          const responseData = await response.json();
          return responseData.accessToken;
        }
      };
    
    const startPolling = (accessToken) => {
        const intervalId = setInterval(async () => {
            try {
                getPaymentButtonStatus(accessToken, tenant, publicKey).then(
                    async (response) => {
                        if (response.status === 200) {
                            const responseData = await response.data;
                            if (responseData.urlRedirect && responseData.urlRedirect.trim()) {
                                setRedirectUrl(responseData.urlRedirect);
                                setIsRedirect(true);
                                clearInterval(intervalId); // Detén el polling cuando se recibe la URL de redirección
                            }
                        }
                    }
                );
            } catch (error) {
            console.error("Error fetching transaction status:", error);
            }
        }, 5000); // Llama a la API cada 5 segundos
    
        return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
    };
    
    useEffect(() => {
        setIsLoading(true);
        login().then((accessToken) => {
            if (status === 'success') {
                setSuccesMessage(true)
                setMessageShow("Thank you for using our services");
                setTypeNextStep(MESSAGE)
            } else {
                setErrorMessageShow(true)
                setMessageShow("Payment rejected, contact our support team");
                setTypeNextStep(ERROR)
            }
            startPolling(accessToken);
            desactiveIsLogin();
        }
    )}
    , []);
    
    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl; // Redirecciona a la URL obtenida
        }
    }, [redirectUrl]);

    return (
        <div>
            {isLoading && (
                <Dimmer active>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            )}
            {isRedirect && (
                <Dimmer active>
                    <Loader size='massive'>Redirecting to merchant website</Loader>
                </Dimmer>
            )}
            {succesMessage && (
                <NextStepComponent
                    message={messageShow} 
                    typeMessage={typeNextStep}
                    >
                </NextStepComponent>

             )}
             {errorMessageShow && (
                <NextStepComponent
                    message={messageShow} 
                    typeMessage={typeNextStep}>
                </NextStepComponent>
             )}
            
        </div>

    );
};

export default CallBackComponent;
