import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'

class NotificationMessage extends Component {
    handleDismiss = () => {
        this.props.setShowNotification(false);
    }
        
    render() {
        return (
            <Message
            warning
            icon='warning sign'
            onDismiss={this.handleDismiss}
            header='Warning!'
            content='Check that the mandatory fields are set correctly.' 
            />
        )
    }
}

export default NotificationMessage
