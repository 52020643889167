import React from 'react'
import { HeaderContent, Header, Icon, Image } from 'semantic-ui-react'

const NotFound = () => (
  <div className='not-found'>   
    <Header as='h1' icon textAlign='center' style={{fontSize:'xxx-large'}}>
      <Icon name='dont' style={{color:'#333'}} />
      <HeaderContent style={{color:'#333'}}>404 - Not Found!</HeaderContent>
    </Header>
  </div>
)

export default NotFound