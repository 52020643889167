import { useState } from "react";
import { Grid, GridColumn, Button, Label } from "semantic-ui-react";
import { getProfile } from "../../service/api";

export default function PaymentMethodSelector({ bankList }) {
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedBankHexColor, setSelectedBankHexColor] = useState(null);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <br></br>
      <h2
        className="text-xl font-bold mb-4"
        style={{ fontFamily: "monospace", textAlign: "center", color: "#182a53" }}
      >
        Selecciona tu canal favorito
      </h2>
      <br></br>

      {/* GRID para distribuir los bancos horizontalmente */}

      <Grid columns={bankList?.length} stackable textAlign="center">
        {bankList?.map((bank) => {
          const isSelected = selectedBank === bank?.bankCode;
          return (
            <Grid.Column key={bank?.bankCode} textAlign="center">
              <Button
                onClick={() => {
                  setSelectedBank(isSelected ? null : bank?.bankCode);
                  setSelectedBankHexColor(isSelected ? null : bank?.hexColor);
                }}
                style={{
                  backgroundColor: isSelected ? bank?.hexColor : "#ffffff",
                  color: isSelected ? "#ffffff" : "#000000",
                  border: `1px solid ${bank?.hexColor}`,
                  borderRadius: "15px",
                  padding: "15px",
                  width: "100%",
                  maxWidth: "200px",
                  minHeight: "82px",
                  display: "inline-block",
                }}
              >
                <img
                  src={`https://d3os9iahmyv2xb.cloudfront.net/${getProfile()}/voucher/V1/collectors/${
                    bank?.bankCode
                  }/${isSelected ? "secondary" : "primary"}.webp`}
                  alt={bank?.bankCode}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "50px",
                    objectFit: "contain",
                  }}
                />
              </Button>
            </Grid.Column>
          );
        })}
      </Grid>

      <Grid columns="equal" reversed="vertically stackable">
        <GridColumn
          style={{
            background: "#ffffff",
            paddingTop: "0px",
            paddingLeft: "0px",
            paddingRight: "0px",
            borderRadius: "15px",
          }}
        >
          <div
            style={{
              background: selectedBankHexColor ? selectedBankHexColor : "#182a53",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
            }}
          >
            <h1 style={{ textAlign: "center", color: "white" }}>
              Estas pagando con: {selectedBank}
            </h1>
          </div>
          <br></br>
          <br></br>
          {selectedBank && (
            <div
              className="mt-6 p-4 bg-white shadow-lg rounded-lg w-full"
              style={{ textAlign: "center" }}
            >
              <h2
                className="text-l font-bold mb-4 text-center"
                style={{ fontFamily: "monospace", color: "#182a53" }}
              >
                INSTRUCCIONES:
              </h2>
              <div
                className="grid gap-4"
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${
                    window.innerWidth > 768
                      ? bankList?.find((b) => b?.bankCode === selectedBank)
                          .paymentMethodInstructions[0].instructions.length
                      : 1
                  }, 1fr)`, // Si el ancho es menor a 768px, usa una sola columna
                }}
              >
                {bankList
                  ?.find((b) => b?.bankCode === selectedBank)
                  .paymentMethodInstructions[0].instructions?.map((instruction, index) => (
                    <div
                      key={index}
                      className="p-4 border border-gray-300 rounded-lg text-center bg-gray-100 shadow-sm"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Label
                        circular
                        size={"large"}
                        style={{
                          background: selectedBankHexColor ? selectedBankHexColor : "#182a53",
                          color: "white",
                        }}
                      >
                        {instruction?.order}
                      </Label>
                      <p
                        className="text-gray-700"
                        style={{
                          margin: "15px",
                          textAlign: "center",
                          wordBreak: "break-word", // Asegura que no haya desbordamiento de texto
                        }}
                        dangerouslySetInnerHTML={{ __html: instruction?.value }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </GridColumn>
      </Grid>
    </div>
  );
}
