import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Header,
  Button,
  Icon,
  Segment,
  Divider,
  Dimmer,
  Loader,
  Statistic,
  Card,
  TextArea,
  Label,
} from "semantic-ui-react";
import {
  endpoints,
  getAccessToken,
  getPaymentButtonInfo,
  getPaymentButtonStatus,
} from "../service/api";
import CounterTimeComponent from "./CounterTimeComponent";
import NextStepComponent from "./NextStepComponent";
import MediaQuery from "react-responsive";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { StatusEnum } from "../model/statusEnum";

const RedirectPaymentButtonComponent = () => {
  const [imageContent, setImageContent] = React.useState("");
  const [textContent, setTextContent] = React.useState("");
  const [totalAmount, setTotalAmount] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [errorMessageShow, setErrorMessageShow] = React.useState(false);
  const [accessTokenState, setAccessTokenState] = React.useState("");
  const [expirationTime, setExpirationTime] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [countSend, setCountSend] = React.useState(0);
  const [createdAt, setCreatedAt] = React.useState(null);
  const [isCopied, setIsCopied] = React.useState(false);

  const params = useParams();
  const { tenant, publicKey } = params;

  const desactiveIsLogin = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const login = async () => {
    const response = await getAccessToken(endpoints.getAccessToken);
    if (response.ok) {
      const responseData = await response.json();
      setAccessTokenState(responseData.accessToken);
      return responseData.accessToken;
    }
  };

  const getStatusInfoPayment = async (publicKey, accessToken) => {
    getPaymentButtonStatus(accessToken, tenant, publicKey).then(
      async (response) => {
        if (response.status == 200) {
          const responseData = await response.data;
          if (responseData.urlRedirect && responseData.urlRedirect.trim()) {
            window.location.replace(responseData.urlRedirect);
          } else {
            return;
          }
        }
      }
    );
  };

  const getData = async (publicKey, accessToken) => {
    getPaymentButtonInfo(accessToken, tenant, publicKey).then(
      async (response) => {
        if (response.ok) {
          const responseText = await response.text();
          const object = JSON.parse(responseText);

          setImageContent(object.imageContent);
          setTextContent(object.textContent);
          setTotalAmount(object.totalAmount);
          setExpirationTime(object.paymentExpiration);
          setCurrency(object.currency);
          setCreatedAt(object.created_at);
          desactiveIsLogin();
        } else {
          setErrorMessageShow(true);
          desactiveIsLogin();
        }
      }
    );
  };

  function handleCopy(text) {
    const textarea = document.createElement("textarea");
    navigator.clipboard.writeText(text);
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
  }

  useEffect(() => {
    if (countSend === 0) {
      login().then((accessToken) => {
        setIsLoading(true);
        getData(publicKey, accessToken);
      });
    }
    if (countSend === 5 || countSend > 5) {
      getStatusInfoPayment(publicKey, accessTokenState);
    }
  }, [countSend]);

  return (
    <div>
      {isLoading && (
        <Dimmer active>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <div>
        <MediaQuery minWidth={768}>
          {/* Contenido para dispositivos de escritorio */}
          <Grid columns="three">
            <Grid.Row />
            <Grid.Row>
              <Grid.Column />
              <Grid.Column>
                <Segment placeholder>
                  <Grid columns={2} stackable textAlign="center">
                    {textContent && <Divider vertical>Or</Divider>}

                    <Grid.Row verticalAlign="middle">
                      {imageContent && (
                        <Grid.Column>
                          <Grid.Row>
                            <Header icon>
                              <Icon name="camera" />
                              Scann Code QR
                            </Header>
                          </Grid.Row>
                          <img
                            src={`data:image/png;base64, ${imageContent}`}
                            style={{ width: 250 }}
                          />
                        </Grid.Column>
                      )}

                      {textContent && (
                        <Grid.Column>
                          <Header icon>
                            <Icon name="copy" />
                            Copy our payment code
                          </Header>
                          <CopyToClipboard
                            text={textContent}
                            onCopy={() => setIsCopied(true)}
                          >
                            <Button primary>
                              {isCopied ? "Copied!" : "Copy"}
                            </Button>
                          </CopyToClipboard>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                  <Grid columns={2} stackable textAlign="center">
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column></Grid.Column>
                      {totalAmount && (
                        <Grid.Column>
                          <Card color="green">
                            <Statistic
                              size="mini"
                              style={{ textAlign: "right" }}
                            >
                              <Statistic.Label>Total amount</Statistic.Label>
                              <Statistic.Value>
                                {currency} {totalAmount}
                              </Statistic.Value>
                            </Statistic>
                          </Card>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                  {!isLoading && (
                    <CounterTimeComponent
                      countSend={countSend}
                      setCountSend={setCountSend}
                      expirationPament={expirationTime}
                      createdDate={createdAt}
                    />
                  )}
                </Segment>
                {errorMessageShow && (
                  <NextStepComponent
                    message={"ERROR"}
                    typeMessage={"ERROR"}
                  ></NextStepComponent>
                )}
              </Grid.Column>
              <Grid.Column />
            </Grid.Row>
          </Grid>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {/* Contenido para dispositivos móviles */}
          <Grid columns="one">
            <Grid.Row />
            <Grid.Row>
              <Grid.Column />
              <Grid.Column>
                <Segment placeholder>
                  <Grid columns={2} stackable textAlign="center">
                    <Divider vertical>Or</Divider>
                    <Grid.Row verticalAlign="middle">
                      {textContent && (
                        <Grid.Column>
                          <Header icon>
                            <Icon name="copy" />
                            Copy our payment code
                          </Header>
                          <br></br>
                          <CopyToClipboard
                            text={textContent}
                            onCopy={() => setIsCopied(true)}
                          >
                            <Button primary>
                              {isCopied ? "Copied!" : "Copy"}
                            </Button>
                          </CopyToClipboard>
                          <Segment
                            style={{ overflow: "hidden" }}
                            textAlign="center"
                          >
                            {textContent}
                          </Segment>
                        </Grid.Column>
                      )}
                      <Grid.Column></Grid.Column>
                      <Grid.Column></Grid.Column>
                      {imageContent && (
                        <Grid.Column>
                          <Grid.Row>
                            <Header icon>
                              <Icon name="camera" />
                              Scann Code QR
                            </Header>
                          </Grid.Row>
                          <Grid.Row>
                            <img
                              src={`data:image/png;base64, ${imageContent}`}
                              style={{ width: 250 }}
                            />
                          </Grid.Row>
                        </Grid.Column>
                      )}
                      
                    </Grid.Row>
                  </Grid>
                  <Grid columns={2} stackable textAlign="center">
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column></Grid.Column>
                      {totalAmount && (
                        <Grid
                          se={{ display: "flex" }}
                          style={{ alignItems: "center" }}
                        >
                          <Card color="green">
                            <Statistic
                              size="mini"
                              style={{ textAlign: "right" }}
                            >
                              <Statistic.Label>Total amount</Statistic.Label>
                              <Statistic.Value>
                                {currency} {totalAmount}
                              </Statistic.Value>
                            </Statistic>
                          </Card>
                        </Grid>
                      )}
                    </Grid.Row>
                  </Grid>
                  <br></br>
                  <br></br>
                  {!isLoading && (
                    <CounterTimeComponent
                      countSend={countSend}
                      setCountSend={setCountSend}
                      expirationPament={expirationTime}
                      createdDate={createdAt}
                    />
                  )}
                </Segment>
                {errorMessageShow && (
                  <NextStepComponent
                    message={"ERROR"}
                    typeMessage={"ERROR"}
                  ></NextStepComponent>
                )}
                <br></br>
                <br></br>
                <br></br>
              </Grid.Column>
              <Grid.Column />
            </Grid.Row>
          </Grid>
        </MediaQuery>
      </div>
    </div>
  );
};

export default RedirectPaymentButtonComponent;
