import axios from "axios";
import { endpoints, getApiUrl } from "../../../service/api";

export const currentHost = `${window.location.protocol}//${window.location.hostname}`;
export const API_OAUTH_CLIENT_ID = process.env.REACT_APP_LOGIN_PAYIN_CLIENT_ID;
export const API_OAUTH_CLIENT_SECRET = process.env.REACT_APP_LOGIN_PAYIN_CLIENT_SECRET;

export async function getPaymentInfo(localAccessToken, tenant, publicKey) {
  let customHeaders = {
    "Access-Control-Allow-Origin": `${currentHost}`,
    "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
    "X-TENANT-ID": `${tenant}`,
    "Content-Type": "application/json",
    Authorization: `Bearer ${localAccessToken}`,
  };
  return await axios.get(
    `${getApiUrl()}/paymentButton/voucherWhiteLabelMonnet/?publicKey=${encodeURIComponent(
      publicKey
    )}`,
    { headers: customHeaders }
  );
}

export const getPaymentStatus = async (localAccessToken, tenant, publicKey) => {
  let customHeaders = {
    "Access-Control-Allow-Origin": `${currentHost}`,
    "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
    "X-TENANT-ID": `${tenant}`,
    "Content-Type": "application/json",
    Authorization: `Bearer ${localAccessToken}`,
  };

  return await axios.get(
    `${getApiUrl()}${endpoints.getPaymentButtonStatus}?publicKey=${encodeURIComponent(publicKey)}`,
    { headers: customHeaders }
  );
};
